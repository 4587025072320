<div id="modal-content-wrapper" class="close-online-account-form-wrapper">
  <section id="modal-body">

    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title class="mat-card-title">
          <div class="header-logo-svg"></div>
          <h2>{{isShellAccount ? 'Delete Shell Account' : 'Close Online Account'}}</h2>
        </mat-card-title>
        <button mat-mini-fab color="warn" (click)="closeModal()" class="btn" type="button" id="dismiss" aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>
      @if (user && closeOnlineAccountForm) {
        <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch"
          >
          <div fxFlex="100">
            <div class="m-1 w-100">
              <form [formGroup]="closeOnlineAccountForm">
                <div class="alignment_new">
                  <label for="reason"><strong>Reason for
                  {{isShellAccount ? 'deleting shell account' : 'closing online account'}}*</strong>
                </label>
                <div>
                  <textarea id="reason" formControlName="reason" maxlength="250"></textarea>
                  @if (closeOnlineAccountForm.controls['reason'].dirty || closeOnlineAccountForm.controls['reason'].touched) {
                    <div
                      class="text-danger">
                      @if (closeOnlineAccountForm.controls['reason'].errors?.required || closeOnlineAccountForm.controls['reason'].hasError('whitespace')) {
                        <div>
                          Reason is required.
                        </div>
                      }
                    </div>
                  }
                </div>
                @if (!isShellAccount) {
                  <div class="disclaimer">
                    <span>Please note that for accounts subscribed to Paperless billing, after the deactivation the account will be set back to Paper Bills.</span>
                  </div>
                }
                <label for="check"><strong>I want to
                  {{isShellAccount ? 'delete this shell' : 'close this online'}}
                account*</strong></label>
                <input type="checkbox" id="check" formControlName="check" class="checkbox3 mt-3 ml-2"
                  tabindex="0" required>
              </div>
            </form>
          </div>
        </div>
      </mat-card-content>
    } @else {
      <div class="m-0 py-2" mat-dialog-content><strong>No Data Found.</strong></div>
    }
    <div class="footer footer-buttons" fxlayoutalign="center center" fxLayoutGap="20px" fxLayout_xs="column">
      <button class="btn btn-sm ux-icon-button-new" (click)="closeModal()">Cancel</button>
      <button (click)="closeOnlineAccount()"
        [disabled]="closeOnlineAccountForm.controls['check'].invalid || closeOnlineAccountForm.controls['reason'].invalid || apiCallPending"
        class="btn btn-sm ux-icon-button-new" data-toggle="tooltip"
        title="Close Online Account">{{isShellAccount ? 'Delete' : 'Close'}} <span
      fxHide_xs>{{isShellAccount ? 'Shell' : 'Online'}}</span> Account</button>
    </div>
    <!-- [ngClass]="{ 'disabled': (!closeOnlineAccountForm.valid || submitted) }" -->

  </mat-card>
</section>
</div>