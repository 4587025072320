@if (formListDS) {
  <div fxLayout="column">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" style="padding: 2rem">
       <!-- @if (formListDS) { -->
        <div fxLayout="row" fxLayout_lt-sm="column" [ngClass]="wrapperQuickSearch" fxFlex="100" fxLayoutAlign="center center"
          fxLayoutGap="5rem" style="margin-bottom: 3.38rem">
          <!--<mat-form-field class="mt-3">
            <mat-label>Quick search</mat-label>
            <input matInput class="form-field"  #quickSearchInput (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="Clear"
              (click)="applyFilter(''); quickSearchInput.value=''">
              <mat-icon matSuffix>close</mat-icon>
            </button>
          </mat-form-field> -->
          <button type="button" class="btn btn-sm" title="Refresh"
            (click)="refreshApi()" fxLayout="row" fxLayoutAlign="center center">
            <!-- <mat-icon data-toggle="tooltip" data-placement="bottom" title="Refresh" class="mr-2">refresh
              </mat-icon> -->
              <span>Refresh</span>
            </button>
            <!--<button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
              (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">file_download
                </mat-icon><span>Export Data</span>
              </button>-->
              <button type="button" [disabled]="concatUsernameInfo.length<1" class="btn btn-sm" title="Delete User " data-toggle="tooltip"
                (click)="deletePendingRegistraions()" fxLayout="row" fxLayoutAlign="center center">
                <!-- <mat-icon class="mr-2">delete</mat-icon> -->
                <span>Delete</span>
              </button>
              <button type="button" [disabled]="concatUsernameInfo.length<1" class="btn btn-sm" title="Renotify User" data-toggle="tooltip"
                (click)="renotifyCustomers()" fxLayout="row" fxLayoutAlign="center center">
                <!-- <mat-icon class="mr-2">notifications</mat-icon> -->
                <span>Renotify</span>
              </button>
            </div>
          <!-- } -->
          <div class="data-table-container">
            <!-- <mat-table  [dataSource]="formListDS" class="live-data-container mat-elevation-z8" matSort
              [ngClass]="wrapperTableResponsive"
              #serviceReqTableSort="matSort" >
              <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef class="sl" [disabled]="true" mat-sort-header><span></span>-->
                <!-- <mat-checkbox  class="header-checkbox align-middle"
                (change)="selectChangeAll($event)">
              </mat-checkbox> -->
            <!--</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index" class="sl" [ngClass]="wrapperCell">
              <span>
                <mat-checkbox  [checked]="element.isSelected"
                  (change)="selectChangeUnique($event,element.username,element.id)">
                </mat-checkbox>
              </span>
            </mat-cell>
          </ng-container>
          @for (attribute of formAttributeConfig; track attribute; let i = $index) {
            <ng-container matColumnDef="{{attribute.name}}">
              <mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class"
                [ngClass]="wrapperHeaderCell" mat-sort-header>
                <div>{{attribute.label}}</div>
              </mat-header-cell>
              <mat-cell class="matCell pl-1 " [class]="attribute.class" *matCellDef="let element;"
                [ngClass]="wrapperCellAttribute">
                @if (attribute.type==='string' || attribute.type==='number') {
                  <span fxFlex_lt-sm="100">
                    <span [ngClass]="wrapperFullName" fxFlex_lt-sm="100"><span class="d-none"
                    [ngClass]="wrapperAttributeLabel" fxFlex_lt-sm="35">{{attribute.label}}:</span>
                    <span fxFlex_lt-sm="65">{{element[attribute.name] || '-'}} </span></span>
                  </span>
                }
                @if (attribute.type==='createDate') {
                  <span fxFlex_lt-sm="100">
                    <span [ngClass]="wrapperFullName" fxFlex_lt-sm="100"><span class="d-none"
                    [ngClass]="wrapperAttributeLabel" fxFlex_lt-sm="35">{{attribute.label}}:</span><span
                  fxFlex_lt-sm="65">{{element[attribute.name]  | date: 'short'}}</span>
                </span>
              </span>
            }
            @if (attribute.type==='notes') {
              <span fxFlex_lt-sm="100">
                <span [ngClass]="wrapperFullName" fxFlex_lt-sm="100"><span class="d-none"
                [ngClass]="wrapperAttributeLabel" fxFlex_lt-sm="36">{{attribute.label}}:</span>
                @if (element.customerId) {
                  <span fxFlex_lt-sm="65">Customer ID = {{element.customerId}}</span>
                }
                @else {
                  <span fxFlex_lt-sm="65">-
                  </span>
                }
              </span>
            </span>
          }
          @if (attribute.type === 'role') {
            <span fxFlex_lt-sm="100"> <span fxFlex_lt-sm="35"
            class="d-none" [ngClass]="wrapperAttributeLabel">{{attribute.label}}:</span>
            @for (role of element[attribute.name]; track role; let i = $index) {
              <span fxFlex_lt-sm="65"> @if (i>0) {
                <span> ,</span>
              }
              {{role.roleCode}}
            </span>
          }
        </span>
      }
      @if (attribute.type==='registrationLinkExpiry') {
        <span fxFlex_lt-sm="100">
          @if (element[attribute.name]>=currentDate) {
            <span fxFlex_lt-sm="100">
              <p><span >N</span></p>
              <p><span  [ngClass]="wrapperFullName" fxFlex_lt-sm="100"><span class="d-none" fxFlex_lt-sm="35"
              [ngClass]="wrapperAttributeLabel">{{attribute.label}}:</span><span fxFlex_lt-sm="65">
            Will expire on ({{element[attribute.name] | date: 'short' : uiTimezone }}) </span>
          </span></p>
        </span>
      }
      @else if (element[attribute.name]< currentDate) {
        <span fxFlex_lt-sm="100">
          <p><span >Y</span></p>
          <p><span  [ngClass]="wrapperFullName" fxFlex_lt-sm="100"><span class="d-none" fxFlex_lt-sm="35"
          [ngClass]="wrapperAttributeLabel">{{attribute.label}}:</span><span fxFlex_lt-sm="65">
        Expired on ({{element[attribute.name] | date: 'short' : uiTimezone }}) </span>
      </span></p>
    </span>
  }
  @else {
    <span fxFlex_lt-sm="65">-</span>
  }
</span>
}
</mat-cell>
</ng-container>
}
<tr class="mat-row d-flex" *matNoDataRow >
  <td class="mat-cell d-flex w-100 p-3 font-weight-bold"
    fxLayoutAlign="center" [attr.colspan]="displayedColumns.length">
    No data matching the filter.
  </td>
</tr>
<mat-header-row [ngClass]="wrapperHeaderCell" *matHeaderRowDef="displayedColumns; sticky: true;"
class="matHeaderRow"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow" fxLayout="row" fxLayout_xs="column"
[ngClass]="wrapperRow"></mat-row>
</mat-table> -->
<uxlib-data-table [dataTableConfig]="dataTableConfiguration" [data]="formListDS"
    (newActionEvent)="getActions($event)"></uxlib-data-table>
<!-- @if (formListDS) {
  <mat-paginator #formsPaginator="matPaginator" [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons [pageSize]="10" aria-label="Select page">
  </mat-paginator>
} -->
</div>
</div>
</div>
}