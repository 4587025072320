@if (auditListDS) {
  <div fxLayout="column">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
      @if (formsFilterForm && advSearchOn) {
        <form [formGroup]="formsFilterForm" (ngSubmit)="getAuditEvents()"
          >
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="mb-4">
            <div fxLayout="column" fxFlex="24.5" fxFlex_lt-sm="80">
              <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By User Name">
                <mat-label>Username:</mat-label>
                <input matInput type="text" placeholder="" formControlName="userName">
              </mat-form-field>
            </div>
            <div fxFlex="1" fxHide_lt-sm></div>
            <!-- <div fxLayout="column" fxFlex="48" class="mt-4 ">
            <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Status">
              <mat-label>Status:</mat-label>
              <mat-select #statusSelect formControlName="status" >
                <mat-option  *ngFor="let sStatus of statusList; let i = index" value="{{sStatus}}">
                  {{sStatus}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div fxLayout="column" fxFlex="24.5" fxFlex_lt-sm="80">
            <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Event Name">
              <mat-label>Event Name:</mat-label>
              <mat-select #eventNameSelect formControlName="eventName">
                @for (eventname of auditEventList; track eventname; let i = $index) {
                  <mat-option value="{{eventname}}">
                   {{(eventname | labelTranslate) || '-'}}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="1" fxHide_lt-sm></div>
          <div fxLayout="column" fxFlex="49" fxFlex_lt-sm="80">
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="48">
                <mat-form-field appearance="fill" data-toggle="tooltip" title="Search From Start Date">
                  <mat-label>From:</mat-label>
                  <input matInput [matDatepicker]="pickerFromDate" formControlName="startDate"
                    (dateChange)="dateChangeHandler('startDate')">
                  <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex="2"></div>
              <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="48">
                <mat-form-field appearance="fill" data-toggle="tooltip" title="Search upto Ent Date">
                  <mat-label>To:</mat-label>
                  <input matInput [matDatepicker]="pickerToDate" formControlName="endDate"
                    (dateChange)="dateChangeHandler('endDate')">
                  <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerToDate></mat-datepicker>
                </mat-form-field>
                <!-- <mat-error *ngIf="formsFilterForm.errors?.dateInvalid || formsFilterForm.invalid">
                Please enter a valid date
              </mat-error> -->
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="mb-4">
        <button class="btn btn-sm ux-icon-button-new" type="submit"
          [disabled]="formsFilterForm.invalid">
          Search
        </button>
        <button class="btn btn-sm ux-icon-button-new" type="button" (click)="onResetFormSubmit()">
          Reset
        </button>
      </div>
    </form>
  }
  <div fxLayout="column" fxLayoutAlign="space-between stretch" class="mt-2">
  <div fxLayout="row" fxLayout_lt-sm="column" fxLayout_lt-md="column" [class_lt-md]="'mb-4'"
    [ngClass_lt-sm]="{'mb-4':true}" fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="15px"
    *ngIf="formsFilterForm" class="mb-2">
    <!--<mat-form-field *ngIf="!advSearchOn">
      <mat-label>Quick search</mat-label>
      <input matInput class="form-field" #quickSearchInput (keyup)="applyFilter($event.target.value)">
      <button matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter(''); quickSearchInput.value=''">
        <mat-icon matSuffix>close</mat-icon>
      </button>
    </mat-form-field>-->
    <button type="button" class="btn btn-sm ux-icon-button-new" title="Toggle Advanced Search Form" sty
      (click)="toggleAdvSearch()" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon data-toggle="tooltip" data-placement="bottom" title="Toggle Advanced Search Form"
        class="mr-2">
      manage_search</mat-icon>
      <span>{{advSearchOn ?'Hide' : 'Show'}} Advanced Search</span>
    </button>
    <button type="button" class="btn btn-sm ux-icon-button-new" title="Refresh" (click)=" onResetFormSubmit()"
      fxLayout="row" fxLayoutAlign="start center">
      <mat-icon data-toggle="tooltip" data-placement="bottom" title="Refresh" class="mr-2">refresh
        </mat-icon><span>Refresh</span>
      </button>
      <!--<button type="button" class="btn btn-sm ux-icon-button mr-2" title="Export Data in CSV format"
        (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">
          file_download
          </mat-icon><span>Export Data</span>
        </button>-->
      </div>
      <!-- <div> -->
        <!-- <mat-table [formGroup]="formsFilterForm" [dataSource]="auditListDS"
        class="live-data-container mat-elevation-z8" matSort
					[ngClass_lt-sm]="
					{'mat-table-responsive':true}" #auditTableSort="matSort">
        <ng-container matColumnDef="index">
          <mat-header-cell class="audit sl-index" *matHeaderCellDef mat-sort-header>
            <span>#</span>
          </mat-header-cell>
          <mat-cell class="audit sl-index" *matCellDef="let element; let i = index">
            <span>{{element.rowIndex}}</span>
          </mat-cell>
        </ng-container>
        <ng-container *ngFor="let attribute of formAttributeConfig; index as i" matColumnDef="{{attribute.name}}">
          <mat-header-cell [ngClass_lt-md]="{'mat-cell-responsive':true}"
            [ngClass_lt-sm]="{'mat-cell-responsive-sm':true}" class="audit pl-1 matHeaderCell" *matHeaderCellDef [disabled]="!attribute.sort" [class]="attribute.class" mat-sort-header>
            <div>{{attribute.label}}</div>
          </mat-header-cell>
          <mat-cell class="matCell audit" [ngClass_lt-md]="{'mat-cell-responsive':true}"
            [ngClass_lt-sm]="{'mat-cell-responsive-sm':true}" *matCellDef="let element;" [class]="attribute.class + ' ' + attribute.type + ' ' + (attribute.class || '')">
            <span *ngIf="!attribute.type || attribute.type === 'string'">
              <span>{{element[attribute.name] || '-'}}</span>
            </span>
            <span *ngIf="attribute.type === 'date'">{{element[attribute.name] | date: 'short' : uiTimezone }}</span>
            <span *ngIf="attribute.type === 'action'">
              <button *ngIf="element[attribute.name] && hasAdditionaDataTypeObj(element[attribute.name])" type="button" class="btn btn-link p-0" data-toggle="tooltip" title="View Additional Data"
                (click)="viewAdditionalData(element)"><span>View</span></button>
                <span *ngIf="element[attribute.name] && !hasAdditionaDataTypeObj(element[attribute.name])" >{{element[attribute.name]}}</span>
                <span *ngIf="!element[attribute.name]">-</span>
              </span>
            </mat-cell>
          </ng-container>
          <tr class="mat-row d-flex" *matNoDataRow>
            <td class="mat-cell d-flex w-100 p-3 font-weight-bold" fxLayoutAlign="center"
              [attr.colspan]="displayedColumns.length">
              No data matching the filter.
            </td>
          </tr>
          <mat-header-row [ngClass_lt-sm]="{'d-none':true}" *matHeaderRowDef="displayedColumns; sticky: true;"
          class="matHeaderRow"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" fxLayout="row" fxLayout_xs="column" class="matHeaderRow"
          [ngClass_lt-md]="{'responsive-mat-row':true}" [ngClass_lt-sm]="{'responsive-mat-row-small':true}"></mat-row>
        </mat-table>
        <div fxLayout="row" fxLayout_lt-sm="column-reverse" class="table-footer">
          <div fxFlex="40" fxFlex_lt-sm="100" fxLayoutAlign="start center" class="table-disclaimers" *ngIf="defaultDateDisclaimer">
            <div class="text-secondary" >
              <span>** Please note: Data is displayed for last 30 days.</span>
            </div>
          </div>
          <div fxFlex="grow" fxFlex_lt-sm="100" fxLayoutAlign="end none">
            <mat-paginator #formsPaginator="matPaginator" *ngIf="formsFilterForm"
              [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons [pageSize]="10"
              aria-label="Select page">
            </mat-paginator>
          </div>
        </div> -->
        <!--
      </div>-->
    </div>
    <uxlib-data-table [dataTableConfig]="dataTableConfiguration" [data]="auditListDS"
    (newActionEvent)="getActions($event)" [advSearchOn]="advSearch"></uxlib-data-table>
  </div>
</div>
}