<mat-card appearance="outlined" fxLayout="column">
  <mat-card-header>Create Customer Management Account</mat-card-header>
  <mat-card-content fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <mat-radio-group [(ngModel)]="selectedOption">
        @for (portal of portals; track portal; let i = $index) {
          <mat-radio-button [value]="portal"
            (change)="selectedOption = $event.value">
            {{portal}}
          </mat-radio-button>
        }
      </mat-radio-group>
      <!-- 	<div>Selected Value is : {{selectedOption}}</div> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="center baseline" fxLayoutGap="20px">
      <form [formGroup]="customerRegForm">
        <mat-label>Search business partner number: </mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" placeholder="Type business partner number" formControlName="customerId">
          <button matSuffix mat-icon-button aria-label="Clear"
            (click)="customerRegForm.controls.searchKey.setValue(null)">
            <mat-icon matSuffix>close</mat-icon>
          </button>
        </mat-form-field>
        @if (customerRegForm.controls.customerId.touched && customerRegForm.controls.customerId.invalid) {
          <mat-error
            >
            @if (customerRegForm.controls.customerId.errors.required) {
              <mat-error>Business Partner Number is
              required.</mat-error>
            }
            @if (customerRegForm.controls.customerId.errors.pattern) {
              <mat-error>Please Enter Business Partner
              Number in digits.</mat-error>
            }
          </mat-error>
        }
      </form>
      <div fxLayoutAlign="center center"><button mat-stroked-button color="primary" (click)="findCustomerbyId();"
      [disabled]="!customerRegForm.valid">Search</button></div>
    </div>
    @if (customerSearched == null && noCustomerMsg == true) {
      <div fxLayout="column" fxLayoutAlign="center center">
        <p class="text-danger">
          <strong>Customer details not found for the provided Business Partner number.</strong>
        </p>
      </div>
    }
    @if (showContent== true && customerSearched !== null) {
      <div fxLayout="column">
        <mat-divider></mat-divider>
        <div>
          <h2>{{customerSearched.firstName}} {{customerSearched.lastName}}</h2>
        </div>
        <div>
          <mat-label>Customer ID: </mat-label> {{customerSearched.customerId}}
        </div>
        <div>
          <mat-label>Mailing Address: </mat-label>
          <p>
            @if ((customerSearched.mailingAddress.careOf != 'NULL' && customerSearched.mailingAddress.careOf != null) && customerSearched.mailingAddress.careOf != '') {
              <span
                >
                {{customerSearched.mailingAddress.careOf}}<br />
              </span>
            }
            @if ((customerSearched.mailingAddress.streetSupplemental1 != 'NULL' && customerSearched.mailingAddress.streetSupplemental1 != null) && customerSearched.mailingAddress.streetSupplemental1 != '') {
              <span
                >
                {{customerSearched.mailingAddress.streetSupplemental1}}<br />
              </span>
            }
            @if ((customerSearched.mailingAddress.streetSupplemental2 != 'NULL' && customerSearched.mailingAddress.streetSupplemental2 != null) && customerSearched.mailingAddress.streetSupplemental2 != '') {
              <span
                >
                {{customerSearched.mailingAddress.streetSupplemental2}}<br />
              </span>
            }
            @if ((customerSearched.mailingAddress.streetNumber != 'NULL' && customerSearched.mailingAddress.streetNumber != null) && customerSearched.mailingAddress.streetNumber != '') {
              <span
                >
                {{customerSearched.mailingAddress.streetNumber}}&nbsp;
              </span>
            }
            @if ((customerSearched.mailingAddress.streetName != 'NULL' && customerSearched.mailingAddress.streetName != null) && customerSearched.mailingAddress.streetName != '') {
              <span
                >
                {{customerSearched.mailingAddress.streetName}}<br />
              </span>
            }
            @if ((customerSearched.mailingAddress.streetUnit != 'NULL' && customerSearched.mailingAddress.streetUnit != null) && customerSearched.mailingAddress.streetUnit != '') {
              <span
                >
                {{customerSearched.mailingAddress.streetUnit}}<br />
              </span>
            }
            <span>
              {{customerSearched.mailingAddress.city}},&nbsp;{{customerSearched.mailingAddress.province}},&nbsp;{{customerSearched.mailingAddress.postalCode}}
            </span>
          </p>
        </div>
        <div>
          @for (email of customerSearched.customerEmails; track email) {
            <li>
              <mat-icon matPrefix>local_post_office</mat-icon>
              {{email.emailAddress}}
              @if (email.standard == true) {
                <span>(Bill Ready Notification Email)</span>
              }
            </li>
          }
        </div>
        @if (selectedOption === 'Property Management Portal') {
          <div fxFlex="100" fxLayout="column">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end stretch">
              <button mat-stroked-button>
                <span>Properties: <span><strong>
                {{unitAndTenantCnt.propertyCnt}}</strong></span></span>
              </button>
              <button mat-stroked-button>
                <span>Tenant Occupied Unit(s):
                  <span><strong>{{unitAndTenantCnt.tenantCount}}</strong></span></span>
                </button>
              </div>
            </div>
          }
        </div>
      }
    </mat-card-content>
  </mat-card>