import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog,  MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IamDataService } from '@londonhydro/iam-api';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { ExportToCsv } from 'export-to-csv';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as _ from 'underscore';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';
import { UserDetailViewComponent } from '../user-detail-view/user-detail-view.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';
import { query } from '@angular/animations';
import { DataTableConfig } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-user-search-form',
  templateUrl: './user-search-form.component.html',
  styleUrls: ['./user-search-form.component.scss']
})
export class UserSearchFormComponent extends AbstractComponent implements OnInit, AfterViewInit, OnDestroy {
  searchUserForm: UntypedFormGroup = this.formBuilder.group({
    searchKey: [''],
    customerId: [''],
    accountId: [''],
    emailAddress: ['']
  });
  blockReason: any[];
  quickSearch = new UntypedFormControl();
  hideImg = false;
  userResponseData: any;
  csrCustomer: any;
  csrUserAcc: any;
  csrAccount: any;
  searchByRolesFormControl = new UntypedFormControl([]);
  searchByStatusFormControl = new UntypedFormControl([]);
  advSearch = false;
  searchType = '';
  searchListType = '';
  searchListQueryParams: string = '';
  roles = [
    {
      value: 'OWNER',
      label: 'Owner',
      checked: false
    },
    {
      value: 'DELEGATE',
      label: 'Delegate',
      checked: false
    },
    {
      value: 'USER',
      label: 'User',
      checked: false
    },
    {
      value: 'ADMIN',
      label: 'Admin',
      checked: false
    },
    {
      value: 'CSR',
      label: 'CSR',
      checked: false
    }
  ];
  status = [
    {
      value: 'Active',
      label: 'Active',
      checked: false
    },
    {
      value: 'Pending',
      label: 'Pending',
      checked: false
    },
    {
      value: 'Locked',
      label: 'Locked',
      checked: false
    },
    {
      value: 'Deactivate',
      label: 'Deactivate',
      checked: false
    },
    {
      value: 'Expired',
      label: 'Expired',
      checked: false
    },
    {
      value: 'Suspended',
      label: 'Suspended',
      checked: false
    }
  ];
  displayedColumns: string[];
  formAttributeConfig: any[] = [
    { name: 'rowIndex', label: '#', type: 'string', class: 'sl-index', sort: true },
    { name: 'username', label: 'Username', type: 'string', class: 'userSearchUsername', sort: true },
    { name: 'fullName', label: 'Full Name', type: 'string', class: 'userSearchFullName', sort: true },
    { name: 'customerId', label: 'Customer ID', type: 'string', class: 'userSearchCustomerId', sort: true },
    { name: 'currentRoles', label: 'Role', type: 'role', class: 'userSearchRole', sort: true },
    { name: 'userStatus', label: 'Status', type: 'status', class: 'userSearchStatus', sort: true },
    { name: 'remark', label: 'Remarks', type: 'string', class: 'userSearchRemarks', sort: true },
    { name: 'action', label: 'Action', type: 'action', class: 'userSearchAction', sort: false }
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild('paginator') paginator: MatPaginator;
  roleParam = '';
  statusParam = '';
  showTable: boolean;
  searchKey = '';
  checkedRoles: any;
  checkedStatus: any;
  isUserViewClicked: boolean;
  UserViewData: any;
  dataList: [];
  brandingName: any;
  breakPoint_observer_subscription_ltsm: Subscription;
  breakPoint_observer_subscription_ltmd: Subscription;
  screensize_ltsm: boolean;
  screensize_ltmd: boolean;
  @ViewChild('userSearchTableSort') sort: MatSort;
  wrapperQuickSearch: string;
  wrapperSlNo: string;
  wrapperTextSl: string;
  userAccountSubscription: Subscription = undefined;
  dataTableConfiguration: DataTableConfig;

  constructor(injector: Injector, private formBuilder: UntypedFormBuilder, private iamDataService: IamDataService, route: ActivatedRoute,
    private toastr: ToastrService, private csrDataService: CsrDataService,
    private matDialog: MatDialog, private datePipe: DatePipe, private breakPointObserver: BreakpointObserver) {
    super(injector);
    this.subscribeFetchUserAccount();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getBrandingList();
    // this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));

    this.breakPoint_observer_subscription_ltsm = this.breakPointObserver.observe([
      Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltsm = true;
      } else {
        this.screensize_ltsm = false;
      }
    });

    this.breakPoint_observer_subscription_ltmd = this.breakPointObserver.observe([
      Breakpoints.Small,Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltmd = true;
      } else {
        this.screensize_ltmd = false;
      }
    });

    this.dataSource = null;
    this.generateDataTableConfig();
    // this.subscribeFetchUserAccount();
  }
  // .pipe(
  //   catchError(error => {
  //     this.toastr.warning('No User Found', '', { disableTimeOut: false });
  //     return of(null);
  //   }))

  generateDataTableConfig(): void{
    this.dataTableConfiguration = new DataTableConfig(this.formAttributeConfig);
    this.dataTableConfiguration.tableFooterDisclaimer = '';
    // this.dataTableConfiguration.advancedSearchEnabled = true;
    this.dataTableConfiguration.quickSearchEnabled = true;
    this.dataTableConfiguration.exportDataEnabled = true;
    // this.dataTableConfiguration.administerShellAccountsEnabled = true;
    // this.dataTableConfiguration.administerRegisteredAccountsEnabled = true;
  }

  subscribeFetchUserAccount(): void {
    this.userAccountSubscription = this.iamDataService.userAccount.subscribe(res => {
        if (res!== null && res.length == 0 && _.isEmpty(res)) {
          this.toastr.warning('No User Found', '', { disableTimeOut: false });
          console.log("no user found!!! ::::", res);
          // this.dataSource.data = null;
          this.dataSource = null;
        } else if(!_.isEmpty(res) && res.length){
          if (this.searchListType === 'SHELL' || (this.checkedRoles?.length === 1 && this.searchListQueryParams.includes('roles=USER'))) {
            res = res.filter((value) => value.authorities.length === 1 && value.authorities[0].roleCode === 'USER');
          }
          _.each(res, (element, index) => {
            element['rowIndex'] = (index + 1);
            element['fullName'] = element['firstName'] && element['lastName'] ?
              element['firstName'].trim() + ' ' + element['lastName'].trim() : (element['firstName'] && !element['lastName'] ? element['firstName'].trim() :
                (!element['firstName'] && element['lastName'] ? element['lastName'].trim() : ''));

            element['userStatus'] = element.status.value;
            element['remark'] = element.status.remark;
            if (element['authorities']) {
              _.each(element['authorities'], (value, i) => {
                if (this.searchListType === 'SHELL') {
                  if (element['authorities'].length === 1 || (element['authorities'].length > 1 && value ?.roleCode !== 'USER')) {
                    element['currentRoles'] = element['currentRoles'] ? element['currentRoles'] + (i > 0 ? ', ' : '') + value ?.roleCode : value ?.roleCode;
                  }
                } else {
                  element['currentRoles'] = element['currentRoles'] ? element['currentRoles'] + (i > 0 ? ', ' : '') + value ?.roleCode : value ?.roleCode;
                }
              });
            }
          });
          this.quickSearch.reset();
          console.log('getSearchUserList fetched', res);
          // this.dataSource.data = res;
          this.dataSource = res;
        }
      });
  }

  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator
  }

  userSessionLoaded(): void {
  }

  onStatusRemoved(data: string) {
    const dataArray = this.searchByStatusFormControl.value as string[];
    this.removeFirst(dataArray, data);
    this.searchByStatusFormControl.setValue(dataArray); // To trigger change detection
  }
  onRolesRemoved(data: string) {
    const dataArray = this.searchByRolesFormControl.value as string[];
    this.removeFirst(dataArray, data);
    this.searchByRolesFormControl.setValue(dataArray); // To trigger change detection
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  searchList(type = ''): void {
    this.iamDataService.reset();
    // this.dataSource.data = null;
    this.dataSource = null;
    let queryParams = '';
    this.searchType = type;
    this.checkRole();
    this.checkStatus();
    if (this.roleParam && this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.roleParam + '&' + 'searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey)
        + '&' + this.statusParam + '&maxResults=0';
    } else if (!this.roleParam && this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey) + '&' + this.statusParam + '&maxResults=0';
    } else if (this.roleParam && !this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey) + '&' + this.roleParam + '&maxResults=0';
    } else if (!this.roleParam && !this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey) + '&maxResults=0';
    } else if (this.roleParam && this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.roleParam + '&' + this.statusParam + '&maxResults=0';
    } else if (this.roleParam && !this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.roleParam + '&maxResults=0';
    } else if (!this.roleParam && this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.statusParam + '&maxResults=0';
    } else if (!this.roleParam && !this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users';
    }

    if (type === 'SHELL') {
      queryParams = '/users?roles=USER&status=Active';
    }
    if (type === 'REGISTERED') {
      queryParams = '/users?roles=OWNER&status=Active';
    }

    let newQueryParams = '';
    if(this.searchUserForm.controls.customerId.value) {
      newQueryParams += 'customerId=' + this.searchUserForm.controls.customerId.value + '&';
    }
    if(this.searchUserForm.controls.accountId.value) {
      newQueryParams += 'accountId=' + this.searchUserForm.controls.accountId.value + '&';
    }
    if(this.searchUserForm.controls.emailAddress.value) {
      newQueryParams += 'emailAddress=' + this.searchUserForm.controls.emailAddress.value + '&';
    }

    if(newQueryParams.length) {
      newQueryParams = newQueryParams.slice(0,-1);
    }

    if(queryParams.length > 6) {
      queryParams += '&' + newQueryParams;
    }
    else {
      queryParams += '?' + newQueryParams;
    }


    this.searchListType = type;
    this.searchListQueryParams = queryParams;

    this.iamDataService.getUserAccount(queryParams);

  }

  checkRole(): void {
    this.roleParam = '';
    this.checkedRoles = _.filter(this.roles, (role: any) => {
      let flagRole = false;
      _.each(this.searchByRolesFormControl.value, (res: any) => {
        if (res.value === role.value) {
          flagRole = true;
        }
      });
      return flagRole;
    });

    if (this.checkedRoles.length === 1) {
      this.roleParam = 'roles=' + this.checkedRoles[0].value;
    } else if (this.checkedRoles.length > 1) {
      let counter = 0;
      for (const rol of this.checkedRoles) {
        this.roleParam = this.roleParam + (counter > 0 ? '&' : '') + 'roles=' + rol.value;
        counter++;
      }
    }
  }

  checkStatus(): void {
    this.statusParam = '';
    this.checkedStatus = _.filter(this.status, (st: any) => {
      let flagStatus = false;
      _.each(this.searchByStatusFormControl.value, (res: any) => {
        if (res.value === st.value) {
          flagStatus = true;
        }
      });
      return flagStatus;
    });

    if (this.checkedStatus.length === 1) {
      this.statusParam = 'status=' + this.checkedStatus[0].value;
    } else if (this.checkedStatus.length > 1) {
      let counter = 0;
      for (const param of this.checkedStatus) {
        this.statusParam = this.statusParam + (counter > 0 ? '&' : '') + 'status=' + param.value;
        counter++;
      }
    }
  }

  getBrandingList(): void {
    this.csrDataService.getBrandingList().subscribe((response: any) => {
      const key = Object.keys(response);
      const brand = key[0]
      this.brandingName = response[brand]['customerPortalName'];
    });
  }
  userDetailViewEnabled(element: any): void {
    console.log('element data::', element);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + element.id;
    dialogConfig.width = '920px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data: element, brandingName: this.brandingName };
    const userDetailViewer = this.matDialog.open(UserDetailViewComponent, dialogConfig);
    userDetailViewer.afterClosed().subscribe((response: any) => {
      if (response === 'success') {
        this.searchList(this.searchType);
      }
    });
  }

  applyFilter(filterValue: string): any {
    console.log('filterValue::', filterValue);
    console.log('this.datasource', this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filteredData.forEach(
      (res: any, count) => {
        res.rowIndex = count + 1;
      });
    if (this.dataSource.paginator) {
      console.log('sanket');
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.breakPoint_observer_subscription_ltmd) {
      this.breakPoint_observer_subscription_ltmd.unsubscribe();
    }
    if (this.breakPoint_observer_subscription_ltsm) {
      this.breakPoint_observer_subscription_ltsm.unsubscribe();
    }
    if(this.userAccountSubscription) {
      this.userAccountSubscription.unsubscribe();
    }
  }

  exportToCSV(filteredData): void {
    const tableDataCSV = [];

    filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        if (attribute ?.type !== 'action') {
          const val = element[attribute.name];
          const key = attribute.label;
          tempObj[key] = val ? val : '';
        }
      }
      if (element?.lastLoginDate){
        tempObj['Last Login Date'] = element.lastLoginDate;
      }
      tableDataCSV.push(tempObj);
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'User_Admin_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
  advancedSearch(): void {
    this.advSearch = !this.advSearch;
  }

  getActions(emittedValue: any): void {
    const obj = JSON.parse(emittedValue);
    console.log('obj:: ', obj)
    if (obj.action === 'export') {
      this.exportToCSV(obj.value);
    } else if (obj.action === 'search') {
      this.searchList(obj.value);
    } else if (obj.action === 'toggle-search') {
      this.advancedSearch();
    } else if (obj.action === 'userDetailView') {
      this.userDetailViewEnabled(obj.value)
    }
  }
}


