<div class="user-detail-view-wrapper">
  <section id="modal-body">
    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class=" p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title class="mat-card-title">
          <div class="header-logo-svg"></div>
          <h2>User Details Viewer</h2>
        </mat-card-title>
        <button mat-mini-fab color="warn" (click)="closeModal()" class="btn float-right" type="button" id="dismiss"
          aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

        <mat-accordion fxLayout="column" class="border border-light p-0">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
              fxLayoutAlign="space-between center">
              <mat-panel-title class="mb-0 text-white">User Details</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
              @if (userData) {
                <div fxFlex="100" fxLayout="row" fxLayout_md="column" fxLayout_sm="column" fxLayout_xs="column"
                  fxLayoutAlign="space-between center" fxLayoutAlign_md="flex-start flex-start" fxLayoutAlign_sm="flex-start flex-start" fxLayoutAlign_xs="flex-start flex-start">
                  <div fxFlex="50" fxFlex_md="100" fxFlex_sm="100" fxFlex_xs="100" class="p-2">
                    <mat-label><span>Login Email: &nbsp;</span></mat-label>
                    <mat-label class="font-weight-bold text-break">
                    {{userData.username}}</mat-label>
                  </div>
                  <div fxFlex="50" fxFlex_md="100" fxFlex_sm="100" fxFlex_xs="100" class="p-2">
                    <span fxFlex class="w-100">
                      <mat-label><span>User
                      Id:&nbsp; </span></mat-label><span
                    class="font-weight-bold text-break label">{{userData.id}}</span>
                  </span>
                </div>
              </div>
            }
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center"
              fxLayoutAlign_xs="space-between" fxLayout_sm="column" fxLayout_xs="column"
              fxLayoutAlign_sm="flex-start flex-start" fxLayoutAlign_xs="flex-start flex-start">
              <div fxFlex="50" fxFlex_sm="100" fxFlex_xs="100" class="p-2">
                @if (userData) {
                  <span fxFlex class="w-100">
                    <mat-label><span>Full Name:&nbsp; </span></mat-label>
                    <span class="font-weight-bold text-break label">
                    {{userData.fullName || '-'}}</span>
                  </span>
                }
              </div>
            </div>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>

      <div fxLayout="column">

        <mat-accordion class="border border-light p-0 mt-2">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
              fxLayoutAlign="space-between center">
              <mat-panel-title class="mb-0 text-white">My Account Activity
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
              <div fxFlex="100" fxLayout="row" fxLayout_sm="column" fxLayout_xs="column"
                fxLayoutAlign="space-between center" fxLayoutAlign_sm="flex-start flex-start" fxLayoutAlign_xs="flex-start flex-start">
                @if (userData?.registrationPending === true) {
                  <div fxFlex="100" class="p-2 label">
                    <h4>Not Registered Yet</h4>
                  </div>
                }
                @if (userData?.registrationPending === false) {
                  <div fxFlex="100" fxLayout="row" fxLayout_sm="column" fxLayout_xs="column"
                    >
                    <div fxFlex="50" fxFlex_sm="100" fxFlex_xs="100" class="p-2">
                      <mat-label><span>User
                      Status:&nbsp;</span></mat-label><span
                      [class]="userData.status.value"
                    class="font-weight-bold label">{{userData.status.value}}</span>
                  </div>
                  @if (userData?.createdDate) {
                    <div fxFlex="50" fxFlex_sm="100" fxFlex_xs="100" class="p-2"
                      >
                      <mat-label><span>Registration Date:&nbsp; </span></mat-label>
                      <span
                      class="font-weight-bold text-break label">{{(userData.createdDate  | date :'mediumDate') || '-'}}</span>
                    </div>
                  }
                </div>
              }
            </div>
            <div fxFlex="100" fxLayout="row" fxLayout_sm="column" fxLayout_xs="column"
              fxLayoutAlign="space-between center" fxLayoutAlign_sm="flex-start flex-start" fxLayoutAlign_xs="flex-start flex-start">
              @if (userData?.registrationPending === false) {
                <div fxFlex="100" class="p-2">
                  <div fxFlex class="w-100">
                    <mat-label><span>Web Account
                    Locked:&nbsp; </span></mat-label><span
                  class="font-weight-bold text-break label">{{userData.locked === false ? 'No' : 'Yes'}}</span>
                </div>
                <!-- <div fxFlex="50">
                <span class="pr-3">Paperless
                  Billing:</span><span><strong>{{userData.paperlessBilling === false ? 'No' : 'Yes'}}</strong></span>
                </div> -->
              </div>
            }
            @if (userData?.registrationPending === false) {
              <div fxFlex="100" class="p-2">
                <div fxFlex class="w-100">
                  <mat-label><span>Last Login
                  Date:&nbsp; </span></mat-label><span
                class="font-weight-bold text-break label">{{userData.lastLoginDate === null ? 'Not found'  : userData.lastLoginDate | date :'mediumDate' }}</span>
              </div>
              <!-- <div fxFlex="50">
              <span>Last Login
                IP:</span><span><strong>{{userData.lastLoginIP}}</strong></span>
              </div> -->
            </div>
          }
        </div>
        @if (userData?.status?.value && userData?.status?.value !== 'Active') {
          <div fxFlex="100" fxLayout="row" fxLayout_sm="column" fxLayout_xs="column"
            fxLayoutAlign="space-between center" fxLayoutAlign_sm="flex-start flex-start" fxLayoutAlign_xs="flex-start flex-start"
            >
            <div fxFlex="50" fxFlex_sm="100" fxFlex_xs="100" class="p-2">
              <div fxFlex class="w-100">
                <mat-label>
                  {{('user.statusdate.' + userData.status.value |  labelTranslate )|| '-'}}:&nbsp;
                </mat-label>
                <span
                class="font-weight-bold text-break label">{{(userData.status?.date  | date :'mediumDate') || '-'}}</span>
              </div>
            </div>
          </div>
        }
      </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div fxLayout="column">
  <mat-accordion class="border border-light p-0 mt-2 mb-2">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
        fxLayoutAlign="space-between center">
        <mat-panel-title class="mb-0 text-white">Portal Access
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
        <div fxFlex="100" fxLayout_sm="column" fxLayout_xs="column" fxLayout="row"
          fxLayoutAlign="space-between center" fxLayoutAlign_sm="flex-start flex-start" fxLayoutAlign_xs="flex-start flex-start">
          <div fxLayout="row" fxLayout_xs="column" class="p-2" fxFlex="100">
            <!--  *ngIf="userData.authorities.length" -->
            <span class="label">Roles Associated with User:&nbsp;</span>
            <span>
              @for (role of userData.authorities; track role; let i = $index) {
                <span>
                  <span class="font-weight-bold text-break label"> @if (i>0) {
                    <span>,</span>
                  }
                {{role.authority}}</span>
              </span>
            }
          </span>
        </div>
        <div fxFlex="50" fxFlex_sm="100" fxFlex_xs="100" class="p-2">
          <mat-label><span>Remarks:&nbsp;</span></mat-label>
          <mat-label class="font-weight-bold text-break">
            <span>
              {{userData.status.remark ? userData.status.remark  : '-'}}
            </span>
          </mat-label>
        </div>
      </div>
    </mat-card-content>
  </mat-expansion-panel>
</mat-accordion>
</div>

<mat-card-actions fxLayout="row" fxLayout_xs="column" fxLayout_sm="column" fxLayoutAlign="center center"
  fxLayoutGap="15px" class="bg-light px-2 fixed-boattom sticky-footer">
  @if (userData.registrationPending) {
    <button data-toggle="tooltip" title="Send Registration Email" type="button"
      class="btn btn-sm ux-icon-button-new" (click)="sendRegistrationEmailViewer()"
    >Send Registration Link</button>
  }
  @if (!notYetRegistered && !userData.suspended) {
    <button data-toggle="tooltip" title="Block Account" type="button" class="btn btn-sm ux-icon-button-new"
      (click)="showBlockAccountViewer()"
    [disabled]="!userData.enabled">Block Account</button>
  }
  @if (!notYetRegistered && userData.suspended) {
    <button data-toggle="tooltip" title="Unblock Account" type="button"
      class="btn btn-sm ux-icon-button-new" (click)="showUnblockAccountViewer()"
    >Unblock Account</button>
  }
  @if (!userData.deactivated && !notYetRegistered && !isCSRrole) {
    <button data-toggle="tooltip" title="Close Account" type="button" class="btn btn-sm ux-icon-button-new"
      (click)="showCloseOnlineAccountViewer()"
    >{{isShellAccount ? 'Delete Shell Account' : 'Close Online Account'}}</button>
  }
  @if (!notYetRegistered) {
    <button data-toggle="tooltip" title="Change Login Email" type="button"
      class="btn btn-sm ux-icon-button-new" (click)="showChangeLoginEmailViewer()"
      [disabled]="userData.pending || userData.status.value === 'Deactivate'">Change Login
    Email</button>
  }
  @if (!isShellAccount && (!brandingConfig[appConfig['brandName']]['hideCustomerViewForUserAdmin'] || brandingConfig[appConfig['brandName']]['hideCustomerViewForUserAdmin'] === false)) {
    <button data-toggle="tooltip" title="View Customer" type="button" class="btn btn-sm ux-icon-button-new"
    (click)="openCustomerView()">Customer View</button>
  }
</mat-card-actions>

<ng-template #noDataBlock>
  <div mat-dialog-content><strong>No Data Found.</strong></div>
</ng-template>
</mat-card-content>
</mat-card>
</section>
</div>

